<template>
  <Stack tag="div" align="center" gap="none" justify="between" class="px-xs pt-md mb-2xs">
    <Stack tag="button" align="center" gap="xs" @click="handleMenuClick">
      <Icon
        type="icon"
        name="chevron-left"
        :size="16"
        filled
        class="text-charcoal-default"
        :data-test-id="dataTestId ? 'backBtn' : ''"
      />

      <Heading
        display-font
        weight="semi"
        size="xs"
        tag="h3"
        uppercase
        align="left"
        class="-tracking-[0.04em]"
        :data-test-id="dataTestId"
        >{{ name }}</Heading
      >
    </Stack>
    <NuxtLink :to="url" v-if="url" @click="setNavigation">
      <Text class="text-mkm-blue-light whitespace-nowrap" data-test-id="seeAll">
        {{ name === "MKM Home" ? "What is MKM Home?" : "See All" }} <span class="sr-only">{{ name }}</span></Text
      >
    </NuxtLink>
  </Stack>
</template>

<script lang="ts" setup>
import type { MenuHeaderProps } from "./menuTypes";

defineProps<MenuHeaderProps>();

const { setNavigation } = useUIState();
const emit = defineEmits(["resetMenu"]);

const handleMenuClick = () => {
  emit("resetMenu");
};
</script>

<template>
  <NuxtLink
    :to="href"
    v-if="href"
    class="flex justify-center items-center gap-2xs flex-col lg:flex-row sm:relative whatever"
    :data-test-id="dataTestId"
    no-prefetch
  >
    <Icon :name="icon" :size="16" class="md:!hidden" filled />
    <Icon :name="icon" :size="24" class="!hidden md:!flex" filled />
    <span class="sr-only">{{ label }}</span>
    <slot />
    <span
      :class="attr.class"
      class="text-[10px] md:text-xs uppercase font-semibold leading-[12px] tracking-wide text-nowrap"
      >{{ label }}</span
    >
  </NuxtLink>

  <button
    v-bind="$attrs"
    :data-test-id="dataTestId"
    class="flex justify-center items-center gap-2xs flex-col lg:flex-row"
    :class="[
      border &&
        'lg:border-sm lg:p-2xs lg:rounded-sm lg:hover:bg-mkm-yellow-dark lg:active:mkm-yellow-light border-charcoal-default',
      attr.class,
    ]"
    v-else
  >
    <div class="relative">
      <div
        v-if="showBadge && cart && cart?.line_items.length > 0"
        class="absolute right-[-5px] top-[-7px] w-[15px] h-[15px] bg-green-default text-white justify-center rounded-pill flex w-[15px] flex-col px-[4px] py-[4px] items-center"
      >
        <Text weight="bold" class="!text-[9px] text-center" data-test-id="cartItemNumber">{{
          cart?.line_items.length.toString()
        }}</Text>
      </div>
      <Icon :name="icon" :size="16" class="md:!hidden" filled />
      <Icon :name="icon" :size="24" class="!hidden md:!flex" filled />
      <span class="sr-only">{{ label }}</span>
    </div>
    <Stack direction="col" gap="3xs">
      <span
        :class="attr.class"
        class="text-[10px] md:text-xs uppercase font-semibold leading-[12px] tracking-wide text-nowrap"
        >{{ label }}</span
      >
      <slot />
    </Stack>
  </button>
</template>

<script lang="ts" setup>
import type { HeaderLinkProps } from "./headerTypes";
const { cart } = useCart();

const attr = useAttrs();

defineOptions({
  inheritAttrs: false,
});

defineProps<HeaderLinkProps>();
</script>

<template>
  <Stack
    tag="section"
    direction="col"
    gap="md"
    align="center"
    class="text-white px-md"
    :breakpoints="{ direction: { lg: 'row' }, justify: { lg: 'between' } }"
  >
    <Stack
      tag="div"
      justify="center"
      align="center"
      direction="col"
      gap="md"
      :breakpoints="{ direction: { lg: 'row' } }"
    >
      <NuxtLink to="/" class="w-[144px] h-auto">
        <SvgLogo name="mkm-yellow" class-name="w-[144px] h-auto" />
        <span class="sr-only">MKM Home Page</span>
      </NuxtLink>

      <Stack tag="div" gap="2xs" direction="col">
        <br-component component="tc-menu" />

        <div>
          <Text size="caption" align="center" v-html="copyrightsHtml" />

          <br-manage-content-button v-if="page.isPreview()" :relative="true" :content="copyright(page)" />
        </div>
      </Stack>
    </Stack>

    <br-component :component="page.getComponent('social')" />
  </Stack>
</template>

<script lang="ts" setup>
/* eslint-disable vue/no-v-html */
import type { Page } from "@bloomreach/spa-sdk";
const props = defineProps<{ page: Page }>();

const copyright = (page: Page) => page.getComponent("copyright");

const copyrightsText = (page: Page) => {
  const { copyrighttext } = copyright(page)?.getModels() ?? {};
  return page.getContent(copyrighttext)?.getData() ?? null;
};

const stripHtmlTags = (html: string) => {
  return html.replace(/<\/?[^>]+(>|$)/g, "");
};

const copyrightsHtml = computed(() => {
  return stripHtmlTags(copyrightsText(props.page)?.content.value);
});
</script>

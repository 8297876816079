<template>
  <USPHeaderBar v-if="blocks" :items="blocks" />
</template>

<script lang="ts" setup>
import type { Component, Page } from "@bloomreach/spa-sdk";
import { useHeaderBar } from "../../../composables/UseContent/useHeaderBar";

const props = defineProps<{ component: Component; page: Page }>();
const { component, page } = toRefs(props);

const blocks = computed(() => useHeaderBar(page.value, component.value).blocks);
</script>

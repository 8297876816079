import { Page, Component } from "@bloomreach/spa-sdk";

export const themeConfig = {
  "mkm-secondary-theme": {
    background: "bg-mkm-yellow-default before:border-y-mkm-yellow-default",
    buttonVariant: "secondary",
    text: "text-mkm-blue-default",
    description: "text-mkm-blue-default",
  },
  "mkm-core-theme": {
    background: "bg-mkm-blue-default before:border-y-mkm-blue-default",
    buttonVariant: "neutral",
    text: "text-white",
    description: "text-mkm-yellow-default",
  },
  "mkm-dark-theme": {
    background: "bg-charcoal-default before:border-y-charcoal-default",
    buttonVariant: "neutral",
    text: "text-white",
    description: "text-white",
  },
} as const;

type ThemeConfig = keyof typeof themeConfig;

export function useDoubleBanner(page: Page, component: Component) {
  const { data, getSrcOf, getLinkOf } = useContentHelpers(page, component);

  const banners = () => {
    if (!data) return [];

    return [data.banner1, data.banner2].map(({ theme, heading, description, image, ctatext, ctalink }) => {
      return {
        description,
        theme: (theme?.selectionValues[0]?.key as ThemeConfig) || "mkm-secondary-theme",
        title: heading,
        cta: {
          ...getLinkOf(ctalink),
          text: ctatext,
        },
        image: getSrcOf(image),
      };
    });
  };

  return {
    banners: banners(),
  };
}

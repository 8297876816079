<template>
  <Stack tag="nav" gap="button-sm" align="center" :breakpoints="{ gap: { sm: 'md', md: 'lg' } }">
    <HeaderLink
      icon="search"
      label="Search"
      @click="setMobileSearch(true)"
      class="md:!hidden"
      data-fs="mobileHeaderSearchIcon"
    />

    <HeaderLink
      icon="question-solid"
      label="Advice"
      @click="setAdviceMenu(!adviceMenu)"
      class="sm:relative"
      data-test-id="adviceBtn"
      data-fs="adviceButton"
    >
      <Dropdown
        data-test-id="adviceMenu"
        v-if="adviceMenu"
        v-on-click-outside="() => setAdviceMenu(!adviceMenu)"
        class="absolute bg-white top-[64px] sm:top-[50px] md:top-[55px] lg:top-[48px] z-[1000] w-full min-w-[360px] sm:w-fit right-none overflow-hidden"
      >
        <HeaderAdviceLinks
          v-if="adviceMenu"
          :advice-links="adviceLinks"
          @close="
            () =>
              // @ts-ignore
              (adviceMenu.value = false)
          "
        />
      </Dropdown>
    </HeaderLink>

    <HeaderLink
      v-if="user"
      icon="user-round"
      label="Account"
      @click="setUserMenu(!userMenu)"
      class="sm:relative"
      data-test-id="accountBtn"
    >
      <Dropdown
        data-test-id="accountMenu"
        v-if="userMenu"
        v-on-click-outside="() => setUserMenu(!userMenu)"
        class="absolute bg-white top-[64px] sm:top-[50px] md:top-[55px] lg:top-[48px] z-[1000] w-full min-w-[360px] sm:w-fit right-none overflow-hidden"
      >
        <HeaderUserMenu
          @close="
            () =>
              // @ts-ignore
              (userMenu.value = false)
          "
        />
      </Dropdown>
    </HeaderLink>

    <!-- If Not logged in, add the sign in ling instead -->
    <HeaderLink
      v-else
      data-test-id="signInBtn"
      data-fs="signInHeaderButton"
      icon="user-round"
      label="Sign In"
      :href="ROUTES.LOGIN"
      class="sm:relative"
    />

    <HeaderLink
      icon="cart"
      label="Basket"
      @click="setBasketTray(false)"
      :show-badge="true"
      data-test-id="cartBtn"
      data-fs="basketHeaderButton"
    >
      <span
        v-if="totalCartAmount"
        data-test-id="cartItemAmount"
        class="text-[10px] md:text-xs uppercase font-bold leading-[12px] tracking-wide text-nowrap hidden lg:block"
        >£{{ totalCartAmount.toFixed(2) }}</span
      >
    </HeaderLink>
  </Stack>
</template>

<script lang="ts" setup>
import { ROUTES } from "@/helpers/routes/routes";
const { setMobileSearch, setUserMenu, userMenu, adviceMenu, setAdviceMenu, setBasketTray } = useUIState();
const { user } = useUser();
const { cart } = useCart();
const { isVatIncluded } = useUIState();

defineProps(["adviceLinks"]);

const totalCartAmount = computed(() => {
  if (cart.value) {
    return isVatIncluded.value ? cart.value?.totals?.total : cart.value?.totals?.subtotal;
  }
  return null;
});

import { vOnClickOutside } from "@vueuse/components";
</script>

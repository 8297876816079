import { Page } from "@bloomreach/spa-sdk";
import { useContentHelpers } from "../UseContent/helpers";
import { PageUSPItem } from "~/components/Content/USP/types";

export function usePageBlocks(page: Page, component: Component) {
  const { data, getLinkOf } = useContentHelpers(page, component);

  return {
    title: data?.title,
    description: data?.description,
    cta: {
      text: data?.buttontext || "",
      color: data?.buttonstyle?.selectionValues[0]?.key || "primary",
      link: getLinkOf(data?.buttonlink),
    },
    usps: data?.usp.map(({ title, description, icon }: PageUSPItem) => {
      return {
        description,
        name: title,
        icon: icon?.selectionValues[0]?.key,
      };
    }),
  };
}

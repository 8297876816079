import type { Component, Page } from "@bloomreach/spa-sdk";

export function useCircleList(page: Page, component: Component) {
  const { data, getLinkOf, getSrcOf } = useContentHelpers(page, component);

  return {
    list: data?.items?.map(({ imageOrTextTitle, image, link: url }: any) => {
      return {
        name: {
          type: imageOrTextTitle?.content[0]?.tabimage ? "image" : "text",
          content: imageOrTextTitle?.content[0]?.tabimage
            ? getSrcOf(imageOrTextTitle?.content[0].tabimage)
            : imageOrTextTitle?.content[0]?.tabheader,
        },
        image: getSrcOf(image),
        link: getLinkOf(url),
      };
    }),
    title: data?.title,
    description: data?.description,
    link: {
      text: data?.buttonText,
      href: getLinkOf(data?.buttonLink)?.href,
    },
  };
}

<template>
  <div class="flex-1 hidden md:block relative" :class="overlay && !mobileSearch && !searchResults ? 'z-10' : 'z-50'">
    <form @submit.prevent="handleSearchSubmit">
      <Input
        @search:input-value="handleSearchSubmit"
        :model-value="search"
        placeholder="What are you looking for?"
        @update:input-value="(val) => handleSearch(String(val))"
        label="Search Products"
        :label-visible="false"
        id="product-search"
        data-test-id="searchInput"
        size="lg"
        :is-search="true"
        :search-classes="hoverInputClasses"
        @keydown.enter="handleSearchSubmit"
        data-fs="desktopHeaderSearch"
      />
    </form>

    <HeaderSearchResults
      :suggestions="suggestions"
      :items="items"
      :loading="isLoading"
      :query="query"
      @update:query="(queryVal) => handleSearch(String(queryVal))"
    />
  </div>
  <Stack
    tag="div"
    align="center"
    justify="start"
    gap="xs"
    class="w-full md:!hidden fixed top-none left-none z-30 py-xs pr-xs pl-2xs bg-white"
    v-if="mobileSearch"
  >
    <button @click="setMobileSearch(false)" tabindex="0">
      <Icon name="arrow-left" :size="16" class="w-6 h-6" />
      <span class="sr-only">Close Search</span>
    </button>

    <div class="flex-1">
      <form @submit.prevent>
        <Input
          v-if="mobileSearch"
          @keydown.esc="setMobileSearch(false)"
          tabindex="1"
          :model-value="search"
          @update:input-value="(val) => handleSearch(String(val))"
          @update:close-input="setMobileSearch(false)"
          class="hover:!ring-mkm-yellow-default hover:!ring-0 focus:!ring-0 !my-none"
          placeholder="What are you looking for?"
          label="Search Products"
          :label-visible="false"
          id="mobile-product-search"
          data-test-id="searchInput"
          :is-search="true"
          @keydown.enter="handleSearchSubmit"
          data-fs="mobileHeaderSearch"
        />
      </form>
    </div>
    <HeaderSearchResults :suggestions="suggestions" :items="items" :loading="isLoading" :query="query" />
  </Stack>
</template>

<script lang="ts" setup>
import debounce from "lodash.debounce";

const router = useRouter();

const search = ref("");
const query = ref("");
const isLoading = ref(false);

const { mobileSearch, setMobileSearch, setSearchResults, setOverlay, overlay, searchResults } = useUIState();
const { suggestions, items, getSuggestions } = useSuggestions();
const { fetchPrice } = usePrices();

const hoverInputClasses = computed(() => {
  return overlay.value
    ? "hover:ring-[6px] hover:!ring-[#6B6000] focus:border-mkm-blue-dark focus:ring-[6px] focus:ring-[#6B6000]"
    : "hover:ring-[6px] hover:ring-[#D6C000] focus:border-mkm-blue-dark focus:ring-[6px] focus:!ring-[#D6C000]";
});

const searchBloomreach = debounce(async (searchTerm: string) => {
  await getSuggestions(searchTerm);
  if (!suggestions.value || suggestions.value.length === 0) setSearchResults(false);
  isLoading.value = false;
}, 200);

const handleSearch = async (searchTerm: string) => {
  // Set the search menu state and loading state
  isLoading.value = true;
  query.value = searchTerm;
  search.value = searchTerm;
  // Run search Query
  if (searchTerm.length > 2) {
    setSearchResults(true);
    setOverlay(true);
    await nextTick(() => searchBloomreach(searchTerm));
  } else {
    setSearchResults(false);
    items.value = [];
    suggestions.value = [];
  }
};

const handleSearchSubmit = async () => {
  setSearchResults(false);
  setMobileSearch(false);
  await router.push({ path: "/search", query: { q: search.value } });
};

watch(items, () => {
  if (items.value) {
    const collectProductIds = items.value.map((item) => item.itemId.id);
    fetchPrice(collectProductIds.join(","));
  }
});
</script>

<template>
  <div
    class="wrapper ui-border-charcoal-dark ui-relative ui-inline-block"
    ref="wrapper"
  >
    <slot />

    <div
      ref="tooltip"
      :style="
        internalState
          ? { opacity: 1, visibility: 'visible' }
          : { opacity: 0, visibility: 'hidden' }
      "
      :class="[
        position === 'top' ? 'tooltip-top' : 'tooltip-bottom',
        classes,
        'tooltip ui-right-1/5 ui-rounded-sm ui-shadow-sm ui-p-xs ui-absolute ui-z-50 ui-transition-all ui-duration-500',
        isDark
          ? 'ui-bg-charcoal-default ui-text-white'
          : 'ui-bg-white ui-text-charcoal-default',
      ]"
    >
      <div
        :class="[
          tooltipClasses,
          'tooltip-content ui-flex ui-gap-xs ui-justify-between ui-items-start ui ui-text-sm ui-leading-sm ui-font-semi before:ui-absolute before:ui-border-xl before:ui-border-transparent',
          isDark
            ? 'before:ui-border-t-charcoal-default'
            : 'before:ui-border-t-white',
        ]"
      >
        {{ text }}
        <slot v-if="$slots.content" name="content" />
        <button v-if="isCloseable" @click="closeTooltip">
          <Icon name="times-thick" :size="16" class="ui-pt-2xs" />
          <p class="sr-only">Close Menu</p>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, Ref, watch, toRef, computed } from "vue";
import Icon from "../Icon/Icon.vue";
import { TooltipProps } from "./types";

const props = defineProps<TooltipProps>();

const emit = defineEmits<{
  (event: "tooltip:close"): void;
}>();

const stateRef = toRef(props, "state");
const wrapper: Ref<HTMLDivElement | null> = ref(null);
const tooltip: Ref<HTMLDivElement | null> = ref(null);
const internalState = ref(false);

function closeTooltip() {
  internalState.value = false;
  emit("tooltip:close");
}

watch(stateRef, (updateState) => {
  internalState.value = updateState;
});

const axisMapping: { [key: string]: string } = {
  left: "tooltip-right",
  right: "tooltip-left",
};

const responsiveClass = (
  propObject: Record<string, string>,
  twClass: string,
  mapping: { [key: string]: string } = {},
) => {
  return Object.entries(propObject).map(([breakpoint, value]) => {
    const mappedValue = mapping[value] || value;
    return `${breakpoint}:${twClass}${mappedValue}`;
  });
};

const tooltipClasses = computed(() => {
  return [
    props.position === "top"
      ? " before:ui-top-full"
      : "before:ui-bottom-full before:ui-rotate-180 ",
    props.axis === "left"
      ? "before:ui-right-tooltip-right"
      : "before:ui-right-tooltip-left",
    ...responsiveClass(props.breakpoints?.position || {}, "before:ui-top-"),
    ...responsiveClass(
      props.breakpoints?.axis || {},
      "before:ui-right-",
      axisMapping,
    ),
  ];
});

onMounted(() => {
  if (wrapper.value && props.isHoverable) {
    wrapper.value.addEventListener("mouseover", () => {
      if (tooltip.value) {
        internalState.value = true;
      }
    });

    wrapper.value.addEventListener("mouseleave", () => {
      if (tooltip.value) {
        internalState.value = false;
      }
    });
  }
});
</script>

<style scoped>
.tooltip {
  min-width: 20rem;
}

.tooltip-top {
  bottom: 125%;
}

.tooltip-bottom {
  top: 125%;
}

.wrapper:hover .tooltip {
  opacity: 1;
  visibility: visible;
}
</style>

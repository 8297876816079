<template>
  <section class="rich-text p-sm sm:p-md bg-white shadow-xs" data-fs="noSearchResults">
    <h3 data-test-id="noResultSearchTips">Search Tips</h3>

    <ul>
      <li>Check your spelling is correct</li>
      <li>Broaden your search by using fewer words</li>
      <li>Try searching by brand or product type</li>
    </ul>

    <h3 data-test-id="noResultAskForHelp">Ask For Help</h3>
    <p>
      Call <span data-test-id="noResultBranch">{{ selectedBranch?.name }}</span> on
      <NuxtLink :to="`tel:${selectedBranch?.contacts?.phone}`" data-test-id="noResultBranchPhone">{{
        selectedBranch?.contacts?.phone
      }}</NuxtLink>
      or
      <NuxtLink :to="`mailto:${selectedBranch?.contacts.email}`" data-test-id="noResultBranchEmail"
        >send us an email.</NuxtLink
      >
    </p>
  </section>
</template>

<script setup lang="ts">
const { selectedBranch } = useBranches();
</script>

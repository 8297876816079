import { Page, Component } from "@bloomreach/spa-sdk";

export function useFeaturedCardsCarousel(page: Page, component: Component) {
  const { data, getLinkOf, getSrcOf } = useContentHelpers(page, component);
  const { string: title, html: description, staticdropdown, featuredcards } = data ?? {};
  const cards = featuredcards?.map(({ title, description, image, link: linkRef, coloritem }: any) => {
    return {
      title,
      description,
      link: getLinkOf(linkRef),
      image: getSrcOf(image),
      colors: coloritem?.map(({ color }: any) => color?.selectionValues[0]?.key) || [],
    };
  });

  const breakpoints = {
    320: {
      slidesPerView: 1.2,
    },
    520: {
      slidesPerView: 1.4,
    },
    768: {
      slidesPerView: 2.2,
    },
    960: {
      slidesPerView: 3.1,
    },
    1024: {
      slidesPerView: Math.min(4, cards?.length),
    },
  };

  return {
    cards,
    title,
    description: description?.value,
    breakpoints,
    theme: staticdropdown?.selectionValues[0]?.key || "light",
  };
}

import { Page } from "@bloomreach/spa-sdk";
import { link } from "../useBloomreach";

export function useContentHelpers(
  page: Page,
  component?: any,
  options?: {
    ref?: string;
  },
) {
  const contentRef = options?.ref || component?.getContentReference()?.$ref;
  const content = page.getContent(contentRef) as any;
  const data = content?.data || content?.getData();

  const getContentOf = (arg: any): any => page.getContent(arg?.$ref);
  return {
    data,
    getContentOf,
    getLinkOf: (elem: any): any => link(elem?.link[0], page),
    getSrcOf: (img: any) => getContentOf(img)?.getOriginal()?.getUrl() || "",
  };
}

export function useBannerHelpers() {
  return {
    breakpoints: [320, 640, 768, 1024, 1280, 1536],
    generateSrcSet(staticUrl: string, resolutionImage: string, sizes: number[]) {
      return sizes
        .map((size) => {
          return `${staticUrl + size},f=auto/${resolutionImage} ${size}w,`;
        })
        .join("");
    },
    generateSizeString(sizes: number[]) {
      return sizes
        .slice(0, -1)
        .map((size) => {
          return `(max-width: ${size}px) 100vw,`;
        })
        .join("");
    },
  };
}

import { computed } from "vue";

export default function useScreen() {
  return {
    screenSize: computed(() => {
      return window?.innerWidth < 768
        ? "sm"
        : window?.innerWidth < 1024
        ? "md"
        : "lg";
    }),
  };
}

<template>
  <aside class="py-2xs w-full">
    <nav role="navigation">
      <ul ref="adviceList" role="menu">
        <li
          v-for="(adviceLink, index) in adviceLinks"
          :key="`${adviceLink}:${index}`"
          :tabindex="0"
          class="h-full w-full hover:bg-grey-default text-left"
          @keydown="handleKeydown"
          role="menuitem"
        >
          <NuxtLink
            v-if="adviceLink"
            :to="adviceLink.href"
            class="block w-full h-full px-xs py-[12px] outline:none"
            :aria-label="`Navigate to ${adviceLink.name}`"
            @click="emit('close')"
          >
            <Text tag="span" weight="semi" align="left" class="outline-none">{{ adviceLink.name }}</Text>
          </NuxtLink>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue";

defineProps(["adviceLinks"]);
const emit = defineEmits(["close"]);

const adviceList = ref<HTMLUListElement | null>(null);

const handleKeydown = (event: KeyboardEvent) => {
  const currentFocus = document.activeElement as HTMLLIElement;
  const items = adviceList.value?.querySelectorAll<HTMLLIElement>("li");
  if (!items) return;

  const itemsArray = Array.from(items);
  const index = itemsArray.indexOf(currentFocus);

  switch (event.key) {
    case "ArrowDown":
      event.preventDefault();
      if (index < itemsArray.length - 1) itemsArray[index + 1].focus();
      break;
    case "ArrowUp":
      event.preventDefault();
      if (index > 0) itemsArray[index - 1].focus();
      break;
  }
};

onMounted(() => {
  const items = adviceList.value?.querySelectorAll<HTMLLIElement>("li");
  if (items && items.length > 0) items[0].focus();
});
</script>

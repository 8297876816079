<template>
  <section class="mx-auto max-w-[1230px] py-lg text-charcoal-default">
    <NuxtLink
      :to="backButtonLink"
      class="uppercase font-bold text-sm leading-[14px] flex items-center gap-[10px] p-xs md:p-[9px] mb-xs md:mb-lg"
    >
      <Icon name="chevron-left" :size="12" />
      Back to Departments
    </NuxtLink>

    <Table :fields="fields" :data="roles" />
  </section>
</template>

<script setup lang="ts">
import type { ContainerItem, Page } from "@bloomreach/spa-sdk";
import { useDepartmentRoles } from "../../composables/UseContent/useDepartmentRoles";
import { useRoute } from "vue-router";
import { computed } from "vue";
import Table from "~/../../packages/blueprint-ui/src/components/Table/Table.vue";

const route = useRoute();

const backButtonLink = computed(() => route.path.slice(0, route.path.lastIndexOf("/")));

const { component, page } = defineProps<{ component: ContainerItem; page: Page }>();
const { roles } = useDepartmentRoles(page, component, route.params.department as string);

const fields = [
  { key: "title", label: "Role" },
  { key: "location", label: "Location" },
  { key: "date", label: "Closing date" },
];
</script>

import type { Component, Page } from "@bloomreach/spa-sdk";
import type { HelpTitleTextReturn } from "~/components/Content/useHelpTitleTextTypes";

export function useHelpTitleText(page: Page, component: Component): HelpTitleTextReturn {
  const { data } = useContentHelpers(page, component);

  return {
    helpTitle: data.helpTitle,
    helpDescription: data.helpText.value,
  };
}

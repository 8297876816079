import type { Maybe } from "@vue-storefront/unified-data-model";

type Attributes<Type = string> = {
  name: Type;
  values: Type[];
};

export type Item = {
  displayName: string;
  slug: string;
  imageSet: {
    original: {
      link: {
        href: string;
      };
    };
  };
  itemId: {
    id: string;
  };
  customAttrs: Attributes[];
};

export type Suggestions = {
  terms?: string;
};

export interface UseSuggestionsState {
  suggestions: Maybe<Suggestions[]>;
  items: Maybe<Item[]>;
}

const useSuggestions = () => {
  const { selectedBranchId } = useBranches();
  const bloomreachCookie = useCookie("_br_uid_2");
  const viewId = computed(() => (selectedBranchId.value ? `b_${selectedBranchId.value}` : "default"));
  const brUid = computed(() => (bloomreachCookie ? bloomreachCookie.value : "noBrCookie"));
  const catalogViews = computed(() => `mkmbs:${viewId.value}`);
  const { BR_ENV_TYPE } = useRuntimeConfig().public;

  const state = useState<UseSuggestionsState>(`useSuggestions`, () => ({
    suggestions: [],
    items: [],
  }));

  const getSuggestions = async (text: string) => {
    const encodedText = text;

    const { data: keywordData, error } = await useAsyncData("getSuggestions", () =>
      useSdk().brSearch.findSuggestions({
        queryName: "getSuggestion",
        text: encodedText,
        queryHint: {
          viewId: viewId.value,
          brEnvType: BR_ENV_TYPE === "STAGING" ? "STAGING" : undefined,
          brUid2: brUid.value ?? undefined,
          catalogViews: catalogViews.value,
          facetFieldFilters: [{ id: "availability_flag", values: ["1", "2", "4"] }],
        },
        // Do not re-format this, BR API expects this format on one line.
        expect: `terms, items { displayName, slug, imageSet { original { link { href } } }, customAttrs { name, values } itemId { id } }`,
      }),
    );

    useHandleError(error.value);

    const availableItems =
      keywordData.value?.items?.filter((item) => {
        const customAttrs = item?.customAttrs?.find((attr) => attr?.name === "availability_flag");
        return customAttrs?.values?.some((value) => value === "1" || value === "2" || value === "4");
      }) ?? [];

    state.value.suggestions = (keywordData.value?.terms as Maybe<Suggestions[] | null>)?.slice(0, 6) ?? null;
    state.value.items = availableItems as Maybe<Item[]>;
  };

  return {
    getSuggestions,
    ...toRefs(state.value),
  };
};

export default useSuggestions;

<template>
  <Accordion tag="nav" is-dark class="md:!hidden text-white mb-xl" v-if="content">
    <AccordionItem
      border-top
      :id="`${column.getName()}-${index}`"
      :label="column.getName()"
      is-dark
      v-for="(column, index) in content(page, component)?.items ?? []"
      :key="`${column.getName()}-${index}`"
      :open-multiple="false"
    >
      <ul class="py-xs">
        <li
          v-for="(child, childIndex) in column.children"
          class="pb-xs last:pb-none text-charcoal-lighter"
          :key="`${child.getName()}-${childIndex}`"
        >
          <NuxtLink :to="child.getLink().href">
            <Text class="pb-xs last:pb-none" data-test-id="footerLink">{{ child.getName() }}</Text>
          </NuxtLink>
        </li>
      </ul>
    </AccordionItem>
  </Accordion>
</template>

<script lang="ts" setup>
import type { Component, Page, Content } from "@bloomreach/spa-sdk";
defineProps<{ component: Component; page: Page }>();

interface ContentWithItems extends Content {
  items: any;
}

const content = (page: Page, component: Component) => {
  const { menu: menuRef } = component.getModels();
  return page.getContent(menuRef) as ContentWithItems;
};
</script>

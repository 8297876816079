<template>
  <button v-if="isLink" class="py-[12px] flex justify-between w-full items-center" @click="handleMenuClick">
    <Text class="text-charcoal-default" align="left" :data-test-id="dataTestId">{{ name }}</Text>
    <Icon type="icon" name="chevron-right" :size="16" filled class="text-mkm-blue-light" />
  </button>

  <NuxtLink v-else :to="url" @click="closeMenu" class="py-[12px] flex justify-between w-full items-center" no-prefetch>
    <Text class="text-charcoal-default" :data-test-id="dataTestId">{{ name }}</Text>
  </NuxtLink>
</template>

<script lang="ts" setup>
import type { MenuLinkProps } from "./menuTypes";

defineProps<MenuLinkProps>();

const { setNavigation } = useUIState();
const emit = defineEmits(["updateMenu"]);

const closeMenu = async () => {
  await nextTick(() => {
    setNavigation();
  });
};

const handleMenuClick = () => {
  emit("updateMenu");
};
</script>

import type { Component, Page } from "@bloomreach/spa-sdk";

export function useCustomerTestimonials(page: Page, component: Component) {
  const { data, getSrcOf } = useContentHelpers(page, component);

  return {
    title: data?.title,
    messages: data?.messages,
    image: getSrcOf(data?.image),
  };
}

import { Component, Page } from "@bloomreach/spa-sdk";

interface RationaleItem {
  header: string;
  subHeader: string;
  buttonText: string;
  buttonLink: string;
  rationaleItem: string[];
}

export function useRegistration(page: Page, component: Component) {
  const registrationRationaleData = page.getContent(component.getModels().registrationrationale)?.getData();

  const cashRationales = registrationRationaleData?.registrationRationaleCompound.find((item: RationaleItem) =>
    item.header.includes("Cash Account"),
  );
  const creditRationales = registrationRationaleData?.registrationRationaleCompound.find((item: RationaleItem) =>
    item.header.includes("Credit Account"),
  );

  return {
    rationales: {
      cashAccount: {
        header: cashRationales.header,
        subheader: cashRationales.subHeader,
        buttonText: cashRationales.buttonText,
        buttonLink: cashRationales.buttonLink,
        rationaleItems: cashRationales.rationaleItem,
      },
      creditAccount: {
        header: creditRationales?.header,
        subheader: creditRationales?.subHeader,
        buttonText: creditRationales?.buttonText,
        buttonLink: creditRationales?.buttonLink,
        rationaleItems: creditRationales.rationaleItem,
      },
      header: registrationRationaleData?.header,
    },
  };
}

import type { Component, Page } from "@bloomreach/spa-sdk";

export function useImageWithLink(page: Page, component: Component) {
  const { getSrcOf } = useContentHelpers(page, component);

  const { PageRef } = component.getModels();
  const content = PageRef ? page.getContent(PageRef)?.getData() : null;

  const { linkOne, linkTwo, linkThree } = (content ?? {}) as any;

  const imageOne = getSrcOf(content?.imageOne);
  const imageTwo = getSrcOf(content?.imageTwo);
  const imageThree = getSrcOf(content?.imageThree);

  return {
    title: content?.displayName,

    linkOne,
    linkTwo,
    linkThree,

    imageOne,
    imageTwo,
    imageThree,
  };
}

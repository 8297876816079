export const ICON_NAMES = [
  "add-star",
  "address-book",
  "alarm",
  "arrow-bottom-left",
  "arrow-bottom-right",
  "arrow-down",
  "arrow-left",
  "arrow-right",
  "arrow-top-left",
  "arrow-top-right",
  "arrow-up",
  "article",
  "book-icon",
  "branch",
  "brochure",
  "calculator",
  "calendar-add",
  "calendar-confirm",
  "calendar-date",
  "calendar-date-time",
  "cart",
  "check-outline",
  "check-solid",
  "check-thick",
  "check-thin",
  "checklist",
  "chevron-down",
  "chevron-left",
  "chevron-right",
  "chevron-up",
  "cog",
  "credit-card-icon",
  "crosshair",
  "delivery",
  "download",
  "drill",
  "easel",
  "elipses",
  "filter",
  "gradient-chevrons",
  "heart-outline",
  "heart-solid",
  "hide",
  "home",
  "info-outline",
  "info-solid",
  "invoice",
  "lightbulb",
  "log-out",
  "mail",
  "menu",
  "minus-thick",
  "minus-thin",
  "mobile",
  "nav-down",
  "nav-left",
  "nav-right",
  "nav-up",
  "package",
  "padlock-locked",
  "padlock-unlocked",
  "pencil",
  "pin-add",
  "pin-blank",
  "pin-dot",
  "pin-minus",
  "plus-thick",
  "plus-thin",
  "pointer-blue",
  "preferences",
  "price-solid",
  "printer",
  "question-outline",
  "question-solid",
  "search",
  "share",
  "show",
  "star-full",
  "star-half",
  "statement",
  "sustainable-option",
  "swap-horizontal",
  "swap-vertical",
  "tag-outline",
  "tag-solid",
  "telephone",
  "time-outline",
  "time-solid",
  "times-thick",
  "times-thin",
  "trash",
  "triangle-down",
  "triangle-left",
  "triangle-right",
  "triangle-up",
  "truck",
  "user",
  "user-add",
  "user-confirm",
  "user-edit",
  "user-remove",
  "user-round",
  "warning-outline",
  "warning-solid",
  "zoom-in",
  "zoom-out",
  "color-circle",
] as const;

export type IconName = (typeof ICON_NAMES)[number];

export type IconProps = {
  name: IconName;
  size: number;
  color?: string;
  className?: string;
};

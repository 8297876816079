<template>
  <Stack
    direction="row"
    justify="center"
    align="center"
    class="py-lg"
    gap="2xs"
    :breakpoints="{
      gap: { sm: 'md' },
    }"
    v-if="data.imageOne || data.imageTwo || data.imageThree"
  >
    <a v-if="data.imageOne && data.linkOne" :href="data.linkOne">
      <img :src="data.imageOne" :alt="data.title" class="h-[50px] sm:h-[88px] object-cover" />
    </a>

    <a v-if="data.imageTwo && data.linkTwo" :href="data.linkTwo">
      <img :src="data.imageTwo" :alt="data.title" class="h-[50px] sm:h-[88px] object-cover" />
    </a>

    <a v-if="data.imageThree && data.linkThree" :href="data.linkThree">
      <img :src="data.imageThree" :alt="data.title" class="h-[50px] sm:h-[88px] object-cover" />
    </a>
  </Stack>
</template>

<script lang="ts" setup>
import type { Component, Page } from "@bloomreach/spa-sdk";
import { useImageWithLink } from "../../composables/UseContent/useImageWithLink";

const props = defineProps<{ component: Component; page: Page }>();
const { component, page } = toRefs(props);

const data = computed(() => useImageWithLink(page.value, component.value));
</script>

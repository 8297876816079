<template>
  <section class="mt-xs mb-xl" v-if="tabs?.length > 0">
    <Slider
      :draggable="true"
      :autoplay="false"
      :slides-per-view="1"
      :total-slides="tabs?.length"
      :items="tabs"
      @slide-change="changeTabIndex"
      @swiper="onSwiper"
    >
      <swiper-slide v-for="(tab, index) in tabs" :key="index" class="m-auto">
        <div class="relative h-[480px] lg:h-[575px]">
          <NuxtImg
            v-if="tab.desktopImage"
            :src="tab.desktopImage"
            :alt="tab.description"
            :loading="index === 0 ? 'eager' : 'lazy'"
            :fetch-priority="index === 0 ? 'high' : 'auto'"
            class="hidden sm:block absolute -z-10 top-none left-none object-cover object-[20%] w-full h-[480px] lg:h-[575px]"
            data-fs="homepageBannerImage"
          />
          <NuxtImg
            v-if="tab.mobileImage"
            :src="tab.mobileImage"
            :alt="tab.description"
            :loading="index === 0 ? 'eager' : 'lazy'"
            :fetch-priority="index === 0 ? 'high' : 'auto'"
            class="sm:hidden absolute -z-10 top-none left-none object-cover object-top w-full h-[480px]"
          />
          <div v-if="tab.isDarkOverlay" class="z-0 absolute top-none w-full h-full bg-charcoal-darker opacity-40" />
          <div
            class="z-20 w-full absolute transform left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-white text-center"
          >
            <h1 class="mb-2xs text-[36px] md:text-[4rem] leading-[45px] md:leading-7xl tracking-tighter font-semi">
              {{ tab.title }}
            </h1>
            <p class="mb-lg font-semi text-xl md:text-[30px] leading-[28px] md:leading-4xl">{{ tab.description }}</p>
            <NuxtLink v-if="tab.buttonLink && tab.buttonText" :to="tab.buttonLink" data-fs="homepageBannerLink">
              <Button variant="primary" size="base" class="mx-auto" v-if="tab.buttonText">
                {{ tab.buttonText }}
              </Button>
            </NuxtLink>
          </div>
        </div>
      </swiper-slide>
    </Slider>

    <Stack
      direction="row"
      align="center"
      justify="center"
      class="mt-sm md:mt-2xs"
      gap="2xs"
      v-if="tabs.length > 1"
      data-fs="heroTabulatedCarousel"
    >
      <button
        v-for="(tab, index) in tabs"
        :key="index"
        @click="changeTabIndex(index)"
        :style="{
          backgroundImage: tab.tabImage && shouldShowImage ? `url(${tab.tabImage})` : 'none',
        }"
        :class="[
          'md:rounded-none md:min-w-[250px] lg:min-w-[350px] p-[5px] md:py-xs md:px-xs lg:p-sm ',
          tab.tabImage
            ? 'bg-cover bg-[70%] md:bg-[50%] bg-no-repeat md:min-h-[105px] md:w-[377px] rounded-round md:rounded-xs'
            : 'rounded-round',
          tab.tabImage && (active === index ? 'opacity-100' : 'opacity-70 hover:opacity-100'),
          active === index
            ? 'bg-charcoal-default text-white font-semi'
            : 'bg-mkm-yellow-default hover:bg-mkm-yellow-dark text-charcoal-default',
        ]"
      >
        <p class="hidden md:block md:text-base leading-lg lg:text-md font-semi" v-if="!tab.tabImage">{{ tab.title }}</p>
        <p class="hidden md:block md:text-sm lg:text-base leading-md" v-if="!tab.tabImage">{{ tab.tabDescription }}</p>
      </button>
    </Stack>
  </section>
</template>

<script setup lang="ts">
import { SwiperSlide } from "swiper/vue";
import type { Component, Page } from "@bloomreach/spa-sdk";
import { ref } from "vue";
import { useHeroBanner } from "../../../composables/UseContent/useHeroBanner";

const props = defineProps<{ component: Component; page: Page }>();
const { component, page } = toRefs(props);

const tabs = computed(() => useHeroBanner(page.value, component.value).tabs);

const active = ref(0);
const swiperRef = ref<any>(null);

const isMediumScreen = (): boolean => (typeof window === "undefined" ? false : window.innerWidth >= 1024);
const shouldShowImage = ref(isMediumScreen());

onMounted(() =>
  window.addEventListener("resize", () => {
    shouldShowImage.value = isMediumScreen();
  }),
);

const changeTabIndex = (index: any) => {
  if (typeof index === "number") {
    active.value = index;
    swiperRef.value.slideTo(index);
  } else if (typeof index === "object") {
    active.value = index.activeIndex;
  }
};

const onSwiper = (swiper: any) => {
  swiperRef.value = swiper;
};
</script>

<template>
  <div
    class="relative ui-my-2xs ui-h-md sm:ui-h-xl md:ui-h-2xl ui-overflow-hidden"
  >
    <div
      ref="wrapper"
      class="ui-absolute ui-right-none h-20 md:ui-static ui-whitespace-nowrap ui-w-screen ui-gap-2xs ui-transition-all ui-duration-200"
    >
      <NuxtLink
        v-for="(item, index) in items"
        :key="index"
        :to="item.link.href"
        :data-test-id="item.title.split(' ').join('')"
        class="[ ui-bg-neutral-light hover:ui-bg-grey-default ] ui-w-screen md:ui-w-[calc(24.625%)] md:ui-ml-[0.5%] md:first:ui-ml-none [ ui-p-[7px] sm:ui-p-[13px] md:ui-p-[11px] ] [ ui-gap-xs ui-justify-center ui-items-center ] ui-inline-block"
      >
        <div
          class="ui-flex ui-gap-2xs md:ui-gap-xs ui-items-center ui-justify-center"
        >
          <Icon
            :name="item.icon"
            class="ui-text-mkm-blue-default ui-inline-block"
            :size="iconSize"
          />
          <div
            class="ui-text-charcoal-default ui-flex ui-flex-row md:ui-flex-col ui-items-center md:ui-items-start ui-gap-2xs md:ui-gap-none"
          >
            <p class="ui-font-bold ui-text-xs sm:ui-text-sm md:ui-text-base">
              {{ item.title }}
            </p>
            <Text
              tag="p"
              :size="screenSize === 'lg' ? 'xs' : 'sm'"
              class="ui-hidden sm:ui-block"
            >
              {{ item.subtext }}
            </Text>
          </div>
        </div>
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Ref, ref, watch, onMounted, nextTick } from "vue";
import Icon from "../Icon/Icon.vue";
import Text from "../Typography/Text/Text.vue";
import { USPHeaderBarProps } from "./types";
import useScreen from "../../composables/useScreen";

const props = defineProps<USPHeaderBarProps>();
const wrapper: Ref<HTMLDivElement | null> = ref(null);
const currentBlock = ref(1);
const { screenSize } = useScreen();

const IconSizeMapper = {
  sm: 16,
  md: 20,
  lg: 24,
};

const iconSize = IconSizeMapper[screenSize.value];

watch(currentBlock, (nextValue) => {
  if (wrapper.value) wrapper.value.style.right = `${(nextValue - 1) * 100}%`;
});

onMounted(async () => {
  await nextTick(() => {
    setInterval(() => {
      currentBlock.value = (currentBlock.value % props.items.length) + 1;
    }, 3000);
  });
});
</script>

import { Page, Component } from "@bloomreach/spa-sdk";

export function useVideo(page: Page, component: Component) {
  const { data } = useContentHelpers(page, component);

  const channelLink = `https://www.youtube.com/channel/${data?.channelid}`;

  return {
    videoIds: data.videoids,
    channelLink,
    breakpoints: {
      320: {
        slidesPerView: 1.1,
      },
      520: {
        slidesPerView: 1.4,
      },
      768: {
        slidesPerView: 2.2,
      },
      960: {
        slidesPerView: 3.1,
      },
    },
  };
}

<template>
  <footer class="py-lg md:py-xl bg-charcoal-default">
    <br-manage-menu-button :menu="content(page, component)" v-if="page.isPreview()" />
    <MobileLinks :component="component" :page="page" />
    <DesktopLinks :component="component" :page="page" />
    <FooterContent :page="page" />
  </footer>
</template>

<script lang="ts" setup>
import type { Component, Page } from "@bloomreach/spa-sdk";
const props = defineProps<{ component: Component; page: Page }>();
const { component, page } = toRefs(props);

const content = (page: Page, component: Component) => {
  const { menu: menuRef } = component.getModels();
  return page.getContent(menuRef);
};
</script>

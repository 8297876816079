<template>
  <Breadcrumbs
    v-if="!redirectHint"
    :items="generateBreadcrumbs()"
    class="mx-xs md:mx-md my-xs md:my-xs"
    data-test-id="breadcrumbs"
  />

  <CategoryFiltersTray
    :is-filter-tray="true"
    :facets="facets"
    :total-found-items="categoryBCData.total"
    v-if="categoryBCData && !redirectHint"
    :active-category-id="currentCategoryBrId"
  />

  <Grid tag="article" gap="3xl" class="bg-neutral-lighter mb-sm" v-if="categoryBCData && !redirectHint">
    <GridItem tag="aside" col-span="4" :breakpoints="{ sm: '3', md: '2', lg: '3', xl: '2' }">
      <CategoryFilters
        v-if="categoryBCData && selectedTab === 'Products' && !redirectHint"
        :is-filter-tray="true"
        :facets="facets"
        :total-found-items="categoryBCData.total"
        :active-category-id="currentCategoryBrId"
        class="hidden sm:block"
      />
    </GridItem>

    <GridItem tag="section" col-span="4" :breakpoints="{ sm: '5', md: '6', lg: '9', xl: '10' }">
      <SearchResultsHeader
        :route-slug="routeSlug"
        :total-articles="totalArticles"
        :auto-correct-query="autoCorrectQuery"
        :auto-correct-suggestions="autoCorrectSuggestions"
      />
      <CategoryToolbar @sort-by="(val: string) => onSortBy(val, true)" v-if="categoryBCData.total === 0" />
      <SearchNotFound v-if="categoryBCData.total === 0" />

      <SearchTabs :a="a" :total-articles="totalArticles" :page="page" @update-tab="(tab) => handleUpdateTab(tab)" />
    </GridItem>
  </Grid>

  <LazyDropShipTray />
</template>

<script setup lang="ts">
import { type BreadcrumbItemProps } from "@/components/UI/Breadcrumbs/breadcrumbTypes";
import { combineAllFacets } from "@/helpers/category";
import type { Component, Page } from "@bloomreach/spa-sdk";
import { onBeforeRouteUpdate } from "vue-router";
import type { Maybe } from "@vue-storefront/unified-data-model";

// Component Props
const { page, component } = defineProps<{ component: Component; page: Page }>();

// Component Composables
const {
  currentCategoryBrId,
  checkIsValidFacetAndSetUrl,
  facets,
  itemsPerPage,
  currentSearchPage,
  onSortBy,
  isBackButtonPressed,
} = useCategoryPage();

const { search, data: categoryBCData } = useCategory();
const { redirectsUrl, fetchKeywordRedirects } = useKeywordRedirect();
const { sortTerm } = useSortBy();
const { initSearch, convertToBRData, resetFilters } = useFilters();
const route = useRoute();
const router = useRouter();

// Component Refs
const selectedTab = ref<string>("Products");

const replaceDoubleQuotes = (str: string) => {
  return str.replaceAll(/"/g, "'");
};

const routeSlug = ref(
  route.query.q === undefined ? "" : decodeURIComponent(replaceDoubleQuotes(route.query.q as string)),
);

const redirectHint = ref<Maybe<string> | undefined>(null);

useHead({
  bodyAttrs: {
    class: "bg-neutral-lighter",
  },
});

const content = (page: Page, component: Component) => {
  const componentRef = component?.getModels()?.pagination?.$ref;
  // @ts-ignore
  return page?.getContent(componentRef)?.model;
};

const articles = (page: Page, component: Component) => {
  const foundArticles = content(page, component)?.items;
  return foundArticles?.map(({ $ref }: { $ref: string }) => page?.getContent($ref)?.getData());
};

const a = articles(page, component);

// Component computed functions
const totalArticles = computed(() => content(page, component)?.total);
const autoCorrectQuery = computed<string>(() => categoryBCData?.value?.queryHint?.autoCorrectQuery);
const autoCorrectSuggestions = computed<string[]>(() => categoryBCData?.value?.queryHint?.autoCorrectQuerySet);

// Component methods and handlers
const handleUpdateTab = (tab: string) => {
  selectedTab.value = tab;
};

const generateBreadcrumbs = (): BreadcrumbItemProps[] => {
  let searchResultsUrl = "";

  Object.keys(route.params).forEach((key, index) => {
    if (route.params[key]) {
      searchResultsUrl =
        index === 0
          ? searchResultsUrl.concat("", route.params[key].toString())
          : searchResultsUrl.concat("/", route.params[key].toString());
    }
  });

  return [{ title: "Search results", href: `/search/${searchResultsUrl}/`, disabled: false }];
};

// Component Initialization
const doBackButtonCheck = () => {
  if (isBackButtonPressed) {
    isBackButtonPressed.value = false;
    return;
  }

  currentSearchPage.value = 1;
};

doBackButtonCheck();

initSearch();

await fetchKeywordRedirects(routeSlug.value);
await search({
  query: routeSlug.value,
  itemsPerPage: itemsPerPage.value,
  offset: currentSearchPage.value === 1 ? 0 : (currentSearchPage.value - 1) * itemsPerPage.value,
  filterValues: convertToBRData(),
  sortTerm: sortTerm.value,
});

if (redirectsUrl.value) {
  redirectHint.value = redirectsUrl.value;
} else if (categoryBCData.value.queryHint?.redirectHint) {
  redirectHint.value = categoryBCData.value.queryHint?.redirectHint?.url;
}

const categories = combineAllFacets(
  categoryBCData.value?.facetResult?.fields || [],
  categoryBCData.value?.facetResult?.ranges || [],
);

// @ts-ignore
facets.value = checkIsValidFacetAndSetUrl(categories) as any[];

// Format redirect URL to work in any environment.
const formatRedirectUrl = (url: string) => {
  return url.replace(/.*\.com/, "");
};

onServerPrefetch(async () => {
  if (redirectHint.value) {
    await router.push(formatRedirectUrl(redirectHint.value));
  }
});

// Client-side redirect
onMounted(async () => {
  if (redirectHint.value) {
    await router.push(formatRedirectUrl(redirectHint.value));
  }
});

// Component Lifecycle Hooks
// eslint-disable-next-line sonarjs/cognitive-complexity
onBeforeRouteUpdate(async (to, from) => {
  await nextTick();
  // Perform necessary actions here
  // For example, if you need to fetch data again when the route changes:
  routeSlug.value = to.query.q === undefined ? "" : decodeURIComponent(replaceDoubleQuotes(to.query.q as string));

  if (!to.query.sort_by || !to.query.sort_value) {
    if (to.query.q !== from.query.q) {
      resetFilters();
      currentSearchPage.value = 1;
    }

    setTimeout(async () => {
      await fetchKeywordRedirects(routeSlug.value);
      await search({
        query: routeSlug.value,
        filterValues: convertToBRData(),
        sortTerm: sortTerm.value,
        itemsPerPage: itemsPerPage.value,
        offset: currentSearchPage.value === 1 ? 0 : (currentSearchPage.value - 1) * itemsPerPage.value,
      });

      if (redirectsUrl.value) {
        redirectHint.value = redirectsUrl.value;
      } else if (categoryBCData.value.queryHint?.redirectHint) {
        redirectHint.value = categoryBCData.value.queryHint?.redirectHint?.url;
      }

      if (redirectHint.value) {
        await router.push(formatRedirectUrl(redirectHint.value));
      }
    }, 200);
  }

  const categories = combineAllFacets(
    categoryBCData.value?.facetResult?.fields || [],
    categoryBCData.value?.facetResult?.ranges || [],
  );

  // @ts-ignore
  facets.value = checkIsValidFacetAndSetUrl(categories) as any[];
});
</script>

<template>
  <section class="my-sm lg:my-3xl" v-if="list?.length > 0">
    <div class="max-w-[1179px] lg:max-w-[1540px] mx-auto md:px-sm lg:px-md">
      <Heading size="md" class="text-center text-charcoal-default mb-sm" align="center">{{ title }}</Heading>

      <Text v-if="description" class="text-center my-sm px-xs" align="center">
        {{ description }}
      </Text>

      <div
        class="grid grid-cols-1 md:grid-cols-7 md:gap-x-sm lg:gap-x-lg md:gap-y-2xl mt-lg"
        :class="[list.length < 7 && '!flex flex-col md:flex-row justify-center']"
      >
        <NuxtLink
          :to="item.link?.href?.startsWith('/') ? item.link?.href : `/${item.link?.href}`"
          class="[ border-b-sm first:border-t-sm border-neutral-light md:border-none ] py-[10px] px-xs sm:py-[12px] sm:px-sm md:p-none flex flex-row md:flex-col gap-xs md:gap-sm justify-start items-center md:items-start text-center w-full md:max-w-[175px]"
          v-for="(item, index) in list"
          :key="index"
          data-test-id="circledListItem"
        >
          <section class="relative group md:w-full">
            <NuxtImg
              v-if="item.image"
              :src="item.image"
              :alt="item.name?.content"
              provider="cloudflare"
              sizes="500px"
              class="object-cover rounded-round max-w-[48px] h-[48px] sm:max-w-[80px] sm:h-[80px] md:max-w-[125px] md:h-[125px] lg:min-w-[175px] lg:h-[175px] mx-auto"
              loading="lazy"
            />
            <div
              class="absolute top-none transform left-1/2 -translate-x-1/2 rounded-round opacity-0 group-hover:opacity-10 bg-charcoal-darker min-w-[48px] h-[48px] sm:min-w-[80px] sm:h-[80px] md:min-w-[125px] md:h-[125px] lg:w-[175px] lg:h-[175px] mx-auto"
            />
          </section>

          <Stack
            direction="col"
            align="center"
            justify="center"
            class="h-[120px] w-full"
            v-if="item.name?.type === 'image'"
          >
            <NuxtImg :src="item.name?.content" class="object-cover" />
          </Stack>
          <Heading
            v-else
            size="xxs"
            class="text-charcoal-default md:mx-auto text-nowrap md:text-wrap text-left md:text-center"
          >
            {{ item.name?.content }}
          </Heading>
        </NuxtLink>
      </div>

      <NuxtLink v-if="link.href" :to="link.href" class="block mx-auto mt-md w-fit text-mkm-blue-light text-xl">
        {{ link.text }}
      </NuxtLink>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { Component, Page } from "@bloomreach/spa-sdk";
import { useCircleList } from "../../../composables/UseContent/useCircleList";

const props = defineProps<{ component: Component; page: Page }>();
const { component, page } = toRefs(props);

const circleList = computed(() => useCircleList(page.value, component.value));

const title = computed(() => circleList.value.title);
const description = computed(() => circleList.value.description);
const list = computed(() => circleList.value.list);
const link = computed(() => circleList.value.link);
</script>

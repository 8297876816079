<template>
  <Stack data-test-id="accountRationale" direction="col" align="center" class="pt-md pb-2xl px-xs md:px-2xl">
    <Stack class="md:mb-xl">
      <Text weight="semibold" size="2xl" class="text-center">{{ header }}</Text>
    </Stack>
    <Stack
      direction="col"
      gap="lg"
      :breakpoints="{ gap: { md: '3xl' }, direction: { md: 'row' } }"
      class="ui-items-stretch md:w-[960px]"
    >
      <Stack direction="col" align="center" class="md:basis-1/2">
        <Stack direction="col" align="center" class="mb-xs">
          <Stack align="center" justify="center" class="bg-mkm-blue-default rounded-round p-sm w-[96px] h-[96px]">
            <SvgIcon color="#FFCC00" name="credit" :width="48" :height="48" />
          </Stack>
          <Text size="2xl" weight="semibold">{{ creditAccount.header }}</Text>
        </Stack>
        <Text size="lg" class="!leading-lg" weight="semibold">{{ creditAccount.subheader }}</Text>
        <Stack direction="col" class="text-charcoal-default ui-flex-1" gap="xs">
          <Stack gap="xs" v-for="(rationale, index) in creditAccount.rationaleItems" :key="index">
            <template v-if="rationale?.title?.length > 0">
              <SvgIcon
                :width="24"
                :height="24"
                color="#297d51"
                :name="useBloomreachIconMapping(rationale.image.selectionValues[0].key)"
              />
              <Text weight="semi">{{ rationale.title }}</Text>
            </template>
          </Stack>
          <Button
            @click="router.push({ path: 'apply-for-credit' })"
            data-test-id="createCreditAccountButton"
            size="md"
            variant="secondary"
            class="min-w-full justify-center mt-sm"
          >
            {{ creditAccount.buttonText }}
          </Button>
        </Stack>
      </Stack>
      <Stack direction="col" align="center" class="md:basis-1/2">
        <Stack direction="col" align="center" class="mb-xs">
          <Stack align="center" justify="center" class="bg-mkm-yellow-default rounded-round p-sm w-[96px] h-[96px]">
            <SvgIcon color="#143981" name="cash" :width="48" :height="48" />
          </Stack>
          <Text size="2xl" weight="semibold">{{ cashAccount.header }}</Text>
        </Stack>
        <Text size="lg" class="!leading-lg" weight="semibold">{{ cashAccount.subheader }}</Text>
        <Stack direction="col" class="text-charcoal-default flex-1 w-full" gap="xs">
          <Stack v-for="(rationale, index) in cashAccount.rationaleItems" :key="index" gap="xs">
            <template v-if="rationale?.title?.length > 0">
              <SvgIcon
                :width="24"
                :height="24"
                color="#297d51"
                :name="useBloomreachIconMapping(rationale.image.selectionValues[0].key)"
              />
              <Text weight="semi">{{ rationale.title }}</Text>
            </template>
          </Stack>
          <Button
            @click="router.push({ path: '/create-account' })"
            data-test-id="createCashAccountButton"
            size="md"
            variant="secondary"
            class="min-w-full justify-center mt-auto"
            >CREATE A CASH ACCOUNT</Button
          >
        </Stack>
      </Stack>
    </Stack>
  </Stack>
</template>

<script setup lang="ts">
import { Component, Page } from "@bloomreach/spa-sdk";
import useBloomreachIconMapping from "~/composables/useIconMappings";

const router = useRouter();
const { page, component } = defineProps<{ component: Component; page: Page }>();
const {
  rationales: { cashAccount, creditAccount, header },
} = useRegistration(page, component);
</script>

enum NonExistentBRPaths {
  Cart = "/cart",
  StyleGuide = "/style-guide",
  ForgottenPassword = "/forgotten-password",
  ResetPassword = "/reset-password",
  SetPassword = "/set-password",
  CreateAccount = "/create-account",
  CheckoutLogin = "/checkout-login",
  ApplyForCredit = "/apply-for-credit",
  OrderConfirmation = "/order-confirmation",
  Brochures = "/brochures",
  About = "/about",
}

export const checkIfPageExistInBR = (route: { path: string }): boolean => {
  const currentPath = route.path;
  const pathWhichNotExistInBr = Object.values(NonExistentBRPaths);

  for (const path of pathWhichNotExistInBr) {
    if (currentPath.includes(path)) {
      return false;
    }
  }

  return true;
};

<template>
  <Stack direction="col" gap="2xs">
    <Text v-if="helpTitle" weight="bold">{{ helpTitle }}</Text>
    <div v-if="helpDescription" v-html="helpDescription" class="help-description" />
  </Stack>
</template>

<script lang="ts" setup>
import type { Component, Page } from "@bloomreach/spa-sdk";
import { useHelpTitleText } from "~/composables/UseContent/useHelpTitleText";

const props = defineProps<{ component: Component; page: Page }>();
const { component, page } = toRefs(props);

const data = computed(() => useHelpTitleText(page.value, component.value));

const helpTitle = computed(() => data.value.helpTitle);
const helpDescription = computed(() => data.value.helpDescription);
</script>

<style>
.help-description a {
  @apply text-mkm-blue-light;
}
</style>

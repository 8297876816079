import { Page } from "@bloomreach/spa-sdk";
import { USPHeaderBarItem, USPHeaderBlock } from "../../../../packages/blueprint-ui/src/components/USPHeaderBar/types";
import { link } from "../useBloomreach";

export function useHeaderBar(page: Page, component: Component) {
  const content = (page: Page, component: any) => {
    const { uspheaderbar } = component.getModels();
    return page.getContent(uspheaderbar);
  };

  return {
    blocks:
      content(page, component)?.getData()?.block === undefined
        ? []
        : content(page, component)
            ?.getData()
            ?.block.map((item: USPHeaderBarItem): USPHeaderBlock => {
              return {
                title: item.title,
                subtext: item.subtext,
                icon: getIconName(item.icon),
                link: item.link?.link.length ? link(item.link.link[0], page) : { href: "#" },
              };
            }),
  };
}

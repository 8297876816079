import { Page, Component } from "@bloomreach/spa-sdk";
import { ButtonProps } from "../../../../packages/blueprint-ui/src/components/Global/Button/types";
import { useContentHelpers } from "./helpers";

export const themeConfig = {
  Blue: {
    background: "bg-mkm-blue-default",
    title: "text-mkm-yellow-default",
    text: "text-white",
    buttonVariant: "neutral" as ButtonProps["variant"],
    iconColor: "text-mkm-blue-light",
  },
  Yellow: {
    background: "bg-mkm-yellow-default",
    title: "text-mkm-blue-default",
    text: "text-charcoal-default",
    buttonVariant: "secondary" as ButtonProps["variant"],
    iconColor: "text-mkm-yellow-light",
  },
};

type ThemeConfig = keyof typeof themeConfig;

export function useMarketingBanner(page: Page, component: Component) {
  const { data, getSrcOf, getContentOf } = useContentHelpers(page, component, {
    ref: component.getModels()?.PageRef,
  });

  return {
    heading: data?.heading,
    isLite: data?.lite,
    bodyCopy: data?.body,
    theme: (data?.theme?.selectionValues[0]?.key.trim() as ThemeConfig) || "Blue",
    image: getSrcOf(data?.image),
    buttonLink: getContentOf(data?.link)?.model?.links?.site.href || "",
    buttonText: data?.text,
  };
}

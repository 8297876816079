<template>
  <Heading v-if="categoryBCData.total === 0" tag="h1" size="md" class="mb-2xs text-charcoal-default">
    We're sorry!
  </Heading>
  <Heading v-if="categoryBCData.total === 0" tag="h2" size="xs" class="mb-sm text-charcoal-default"
    >We couldn’t find anything to match
    <span class="text-mkm-blue-default" data-test-id="searchResultValue">“{{ routeSlug }}”</span></Heading
  >
  <Heading tag="h1" v-if="autoCorrectQuery" size="xs" class="text-charcoal-default mb-sm"
    >No Results found for "{{ routeSlug }}"</Heading
  >

  <Heading
    v-if="categoryBCData && categoryBCData.total > 0"
    tag="h1"
    size="lg"
    class="text-charcoal-default mb-sm"
    data-test-id="showingItems"
  >
    Showing {{ totalResults }} results for
    <span class="text-mkm-blue-default">“{{ autoCorrectQuery ? autoCorrectQuery : routeSlug }}”</span>
  </Heading>

  <Stack v-if="autoCorrectSuggestions && autoCorrectSuggestions.length > 0" class="mb-lg" direction="col" gap="2xs">
    <Text tag="p" size="md" class="text-charcoal-default">Not found what you’re looking for? Try one of these:</Text>
    <NuxtLink
      v-for="(suggestion, index) in autoCorrectSuggestions"
      :key="`${suggestion}:${index}`"
      :to="`/search?q=${suggestion}`"
      class="text-mkm-blue-light flex gap-xs items-center"
      :data-test-id="`autosuggestedValue:${index}`"
    >
      <Icon name="search" :size="16" />
      <Text tag="span" size="md" weight="bold">{{ suggestion }}</Text>
    </NuxtLink>
  </Stack>

  <form class="mb-sm">
    <Text size="md" weight="bold" class="text-charcoal-default">
      {{ categoryBCData.total > 0 ? "Or try searching again" : "Try searching again" }}</Text
    >

    <Stack tag="div" align="center" direction="col" :breakpoints="{ direction: { sm: 'row' } }" gap="2xs">
      <Input
        :model-value="newSearch"
        placeholder="What are you looking for?"
        label="Search Products"
        :label-visible="false"
        @update:input-value="(val) => handleSearch(String(val))"
        id="search-again"
        :is-search="true"
        class="w-full sm:max-w-[388px]"
        @keydown.enter="handleSearchSubmit"
        data-fs="searchAgain"
        data-test-id="searchInputNoResults"
      />

      <Button
        type="button"
        variant="secondary"
        size="md"
        class="!w-full !justify-center sm:!w-unset"
        @click="handleSearchSubmit"
        data-test-id="searchAgainBtn"
        >Search Again</Button
      >
    </Stack>
  </form>
</template>

<script setup lang="ts">
const { data: categoryBCData } = useCategory();
const router = useRouter();
import { useCustomEvents } from "mkm-gtm";
const { user } = useUser();
const { selectedBranch } = useBranches();

const props = defineProps<{
  routeSlug: string;
  totalArticles: number;
  autoCorrectQuery: string;
  autoCorrectSuggestions: string[];
}>();

const newSearch = ref<string>("");
const { pushCustomEvent, CustomEvents } = useCustomEvents();

const totalResults = computed(() => {
  const totalItems = categoryBCData.value.total ?? 0;
  const totalArticlesSafe = props.totalArticles ?? 0;
  return totalItems + totalArticlesSafe;
});

const handleSearch = (val: string) => {
  newSearch.value = val;
};

const handleSearchSubmit = async () => {
  if (newSearch.value === "") return;

  router.push({ query: { q: newSearch.value, p: 1 } });
};

onMounted(() => {
  setTimeout(async () => {
    const userCategory = user.value?.account.business_type.split("|");
    const isTrade = userCategory?.[0] === "NTrade" ? "NON-TRADE" : userCategory?.[0] === "Trade" ? "TRADE" : null;
    await pushCustomEvent(CustomEvents.VIEW_SEARCH_RESULTS, {
      logged_in: !!user.value,
      user_id: user.value?.id ?? null,
      customer_category_1: user ? isTrade : "Guest",
      customer_category_2: userCategory?.[1] ?? null,
      customer_category_3: userCategory?.[2] ?? null,
      account_type: user.value?.account.type ?? null,
      account_owning_branch: user.value?.account.branch_id ?? null,
      selected_branch: selectedBranch.value?.name ?? null,
      search_term: props.routeSlug,
      page_type: "search",
    });
  }, 2000);
});
</script>

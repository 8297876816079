<template>
  <Stack tag="section" class="h-xl bg-mkm-blue-default px-xs sm:px-sm text-white !hidden lg:!flex" align="center">
    <br-manage-menu-button v-if="page.isPreview()" :menu="menu" />

    <Stack tag="ul" gap="md">
      <li v-for="(item, index) in menuList" :key="`${item.name}:${index}`">
        <NuxtLink
          v-if="item.type === 'internal'"
          :to="item.url"
          class="flex items-center font-bold text-sm"
          data-test-id="menuItem"
        >
          <Icon v-if="item.icon" :name="item.icon" :size="12" class="mr-2xs" />
          <Text tag="span" weight="bold" size="sm" uppercase>{{ item.name }}</Text>
        </NuxtLink>
      </li>
    </Stack>
  </Stack>
</template>

<script setup lang="ts">
import type { Component, Page } from "@bloomreach/spa-sdk";
import { ContentWithItems, NavigationItem, MenuItem } from "./headerTypes";
import { ROUTES } from "@/helpers/routes/routes";

const { user, isAdmin, isCredit } = useUser();

const props = defineProps<{ component: Component; page: Page }>();

const { page, component } = toRefs(props);
const menuList = ref<NavigationItem[]>([]);

const { menu: menuRef } = component.value.getModels();
const menu = page.value.getContent(menuRef);
const menuItems = (menu as ContentWithItems)?.model?.data?.siteMenuItems as MenuItem[];

const formatMenuItems = (menuItems: MenuItem[]) => {
  return menuItems.map((item: any) => {
    return {
      name: item?.name,
      url: item?.links?.site?.href,
      type: item?.links?.site?.type,
    };
  });
};

const setInitialState = () => {
  if (!user.value) {
    return formatMenuItems(menuItems);
  }

  if (isCredit.value && isAdmin.value) {
    return [
      {
        name: "Your Orders",
        url: ROUTES.ACCOUNT_ORDERS,
        icon: "statement",
        alt: "Orders icon",
        type: "internal",
      },
      {
        name: "Your Invoices",
        url: ROUTES.ACCOUNT_INVOICES,
        icon: "invoice",
        alt: "Invoices icon",
        type: "internal",
      },
      {
        name: "Get The Mkm App",
        url: ROUTES.LANDING_MKMAPP,
        icon: "mobile",
        alt: "Invoices icon",
        type: "internal",
      },
    ];
  }

  return [
    {
      name: "Your Orders",
      url: ROUTES.ACCOUNT_ORDERS,
      icon: "statement",
      alt: "Orders icon",
      type: "internal",
    },
  ];
};

menuList.value = setInitialState() as NavigationItem[];

watch(user, () => {
  menuList.value = setInitialState() as NavigationItem[];
});
</script>

<template>
  <ImageWithText
    v-if="title"
    :content="{
      title: title,
      description: description || 'This is an image with text section.',
      imageUrl: image || 'https://via.placeholder.com/328x232',
      link: link,
      alt: title,
      backgroundColor: backgroundColor,
      imagePosition: orientation,
    }"
  />
</template>

<script lang="ts" setup>
import type { Component, Page } from "@bloomreach/spa-sdk";
import { useImageWithText } from "../../composables/UseContent/useImageWithText";

const props = defineProps<{ component: Component; page: Page }>();
const { component, page } = toRefs(props);

const section = computed(() => useImageWithText(page.value, component.value));

const title = computed(() => section.value.title);
const description = computed(() => section.value.description);
const image = computed(() => section.value.image);
const link = computed(() => section.value.link);
const backgroundColor = computed(() => section.value.backgroundColor);
const orientation = computed(() => section.value.orientation);
</script>

import type { Component, Page } from "@bloomreach/spa-sdk";

export function useImageWithText(page: Page, component: Component) {
  const { getSrcOf, getContentOf, getLinkOf } = useContentHelpers(page, component);

  const { imageWithDifferentOrientation } = component.getModels();
  const data =
    getContentOf(imageWithDifferentOrientation)?.model?.data?.imageWithDifferentOrientationAndTextContentType
      ?.imageWithDifferentOrientationAndText?.[0];

  return {
    title: data?.title ?? null,
    description: data?.description?.value ?? null,
    image: getSrcOf(data?.image) ?? null,
    link: {
      href: getLinkOf(data?.textLinkAsNotRequired?.link)?.href ?? null,
      text: data?.textLinkAsNotRequired?.text,
    },
    orientation: data?.orientation?.selectionValues[0]?.label,
    backgroundColor: data?.backgroundcolour?.selectionValues[0]?.label,
  };
}

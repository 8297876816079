<template>
  <div
    class="absolute bg-white top-none left-none h-full w-full transform transition-transform duration-150"
    :class="[isOpen ? 'translate-x-0' : 'translate-x-[100%]', levelClass[level]]"
  >
    <slot />
  </div>
</template>

<script lang="ts" setup>
import type { MenuLayerProps } from "./menuTypes";

defineProps<MenuLayerProps>();

const levelClass = {
  ["1"]: "z-40",
  ["2"]: "z-50",
};
</script>

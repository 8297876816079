<template>
  <div
    v-if="content?.[0]?.content?.value"
    v-html="content[0].content.value"
    class="p-md md:p-xl mb-md rich-text content-container"
  />
</template>

<script lang="ts" setup>
import { useContentHelpers } from "../../composables/UseContent/helpers";
import type { Component, Page } from "@bloomreach/spa-sdk";

const props = defineProps<{ component: Component; page: Page }>();
const { component, page } = toRefs(props);

const { getContentOf } = useContentHelpers(page.value, component.value);

const content = computed(() => Object.values(component.value.getModels()).map((it) => getContentOf(it)?.getData()));
</script>

import type { Component, Page } from "@bloomreach/spa-sdk";

export function useKitchenList(page: Page, component: Component) {
  const { data, getSrcOf, getLinkOf } = useContentHelpers(page, component);

  return {
    data,
    kitchens: data?.kitchenitems.map((kitchen: any) => {
      return {
        title: kitchen.title,
        description: kitchen.description,
        image: getSrcOf(kitchen.image),
        link: getLinkOf(kitchen.link),
        colors: kitchen.coloritems.map((color: any) => color.color?.selectionValues?.[0]?.key),
      };
    }),
  };
}

import { Page, Component } from "@bloomreach/spa-sdk";

export function useLogoCarousel(page: Page, component: Component) {
  const { data, getSrcOf, getLinkOf } = useContentHelpers(page, component);

  return {
    title: data?.title,
    logos: data?.logos?.map(({ image, link: linkRef }: any) => {
      return {
        image: getSrcOf(image),
        link: getLinkOf(linkRef),
      };
    }),
  };
}

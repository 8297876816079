import { Page, Component } from "@bloomreach/spa-sdk";

export function usePatternList(page: Page, component: Component) {
  const { data, getSrcOf, getLinkOf } = useContentHelpers(page, component, {
    ref: component.getModels()?.PageRef,
  });

  return {
    heading: data?.patternHeading,
    subHeading: data?.patternSubheading,
    intro: data?.patternIntro,
    cta: {
      href: getLinkOf(data?.patternButtonLink).href,
      text: data?.patternButtonText,
    },
    patterns: data?.patternListItem.map((pattern: any) => ({
      title: pattern.patternLabel,
      image: getSrcOf(pattern.patternImage),
      link: getLinkOf(pattern.patternLink),
    })),
  };
}

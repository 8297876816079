<template>
  <Stack
    tag="nav"
    gap="lg"
    :breakpoints="{ gap: { lg: '2xl', xl: 'footer-4xl' } }"
    justify="center"
    class="!hidden md:!flex text-white mb-xl"
    v-if="content"
  >
    <Stack
      tag="ul"
      gap="none"
      justify="between"
      v-for="(column, index) in content(page, component)?.items ?? []"
      :key="index"
      class="w-[210px]"
    >
      <li>
        <Text size="md" weight="bold" class="pb-xs">{{ column.getName() }}</Text>
        <Stack
          direction="col"
          tag="ul"
          gap="none"
          v-for="(child, childIndex) in column.children"
          :key="`${child.getName()}-${childIndex}`"
          class="text-charcoal-lighter"
        >
          <li class="pb-[12px]">
            <NuxtLink :to="child.getLink().href" class="hover:text-white" data-test-id="footerLink">
              {{ child.getName() }}
            </NuxtLink>
          </li>
        </Stack>
      </li>
    </Stack>
  </Stack>
</template>

<script lang="ts" setup>
import type { Component, Page, Content } from "@bloomreach/spa-sdk";
defineProps<{ component: Component; page: Page }>();

interface ContentWithItems extends Content {
  items: any;
}

const content = (page: Page, component: Component) => {
  const { menu: menuRef } = component.getModels();
  return page.getContent(menuRef) as ContentWithItems;
};
</script>

import { Component, Page } from "@bloomreach/spa-sdk";

export function useLogin(page: Page, component: Component) {
  const loginRationaleData = page.getContent(component.getModels().loginrationale)?.getData();

  return {
    rationaleItems: loginRationaleData?.rationaleItem,
    header: loginRationaleData?.header,
  };
}

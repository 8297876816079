import { Page, Component } from "@bloomreach/spa-sdk";
import { useContentHelpers, useBannerHelpers } from "./helpers";

const generateImageUrls = (image: any, mobileImage: any, srcGetter: (img: any) => string) => {
  const { breakpoints: sizes, generateSrcSet } = useBannerHelpers();

  const staticUrl = "https://mkmbs.bloomreach.io/cdn-cgi/image/w=";
  const highResolutionImage = image?.$ref ? srcGetter(image) : mobileImage?.$ref ? srcGetter(mobileImage) : "";
  const lowResolutionImage = mobileImage?.$ref ? srcGetter(mobileImage) : image?.$ref ? srcGetter(image) : "";
  const mobileSrc = `${staticUrl + sizes[sizes.length - 1]},f=auto/${lowResolutionImage}`;
  const desktopSrc = `${staticUrl + sizes[sizes.length - 1]},f=auto/${highResolutionImage}`;
  const priorityDesktop = generateSrcSet(staticUrl, highResolutionImage, sizes);
  const priorityMobile = generateSrcSet(staticUrl, lowResolutionImage, sizes);
  return { mobileSrc, desktopSrc, priorityDesktop, priorityMobile, highResolutionImage, lowResolutionImage };
};

export function useHeroBanner(page: Page, component: Component) {
  const { data, getLinkOf, getSrcOf } = useContentHelpers(page, component);
  const { breakpoints: sizes, generateSizeString } = useBannerHelpers();

  const tabs = () => {
    return data?.items?.map(
      ({ title, description, tab, buttontext, buttonlink, image, mobileImage, isDarkOverlay }: any) => {
        const tabContent = { header: "", description: "", image: "" };

        if (tab?.tabcontent[0]?.tabimage) {
          const imageRef = tab.tabcontent[0].tabimage.$ref;
          // @ts-ignore
          tabContent.image = imageRef ? page?.getContent(imageRef)?.getOriginal()?.getUrl() : "";
        } else {
          tabContent.header = tab?.tabcontent[0]?.tabheader;
          tabContent.description = tab?.tabcontent[0]?.tabdescription;
        }

        const { mobileSrc, desktopSrc, priorityDesktop, priorityMobile, highResolutionImage, lowResolutionImage } =
          generateImageUrls(image, mobileImage, getSrcOf);

        const sizeString = generateSizeString(sizes);

        return {
          title,
          description,
          tabHeader: tabContent.header,
          tabDescription: tabContent.description,
          tabImage: tabContent.image,
          buttonText: buttontext,
          desktopImage: highResolutionImage,
          mobileImage: lowResolutionImage,
          buttonLink: getLinkOf(buttonlink)?.href,
          isDarkOverlay,
          priorityDesktop,
          priorityMobile,
          sizeString,
          mobileSrc,
          desktopSrc,
        };
      },
    );
  };

  return {
    tabs: tabs(),
  };
}

import { ContainerItem, getContainerItemContent, type Page } from "@bloomreach/spa-sdk";

export function useEditorialCards(page: Page, component: ContainerItem) {
  const { getLinkOf, getSrcOf } = useContentHelpers(page, component);
  const data = getContainerItemContent(component, page) as any;

  const { orientation, componentsharedfields, editorialcarditemNew: cards } = data ?? {};
  const { header, intro, paragraph, buttontext, mkmLink } = componentsharedfields ?? {};
  return {
    header,
    intro,
    orientation: orientation?.selectionValues?.[0].label,
    cards: cards?.map((card: any) => {
      return {
        title: card.title,
        description: card.descriptionField?.value,
        paragraph: card.paragraph,
        image: getSrcOf(card.image),
        link: getLinkOf(card.mkmLink),
        buttontext: card.buttontext,
      };
    }),
    paragraph,
    link: {
      text: buttontext,
      href: getLinkOf(mkmLink)?.href,
    },
  };
}

import { Page, Component } from "@bloomreach/spa-sdk";

// export function useChevronBanner() {
export function useChevronBanner(page: Page, component: Component) {
  const { data, getSrcOf } = useContentHelpers(page, component);

  return {
    title: data?.title,
    description: data?.description,
    image: getSrcOf(data?.image),
    theme: data?.theme?.selectionValues[0]?.key || "yellow",
  };
}

import { Page, Component, isDocument } from "@bloomreach/spa-sdk";

export function useArticleList(page: Page, component: Component) {
  const { getSrcOf } = useContentHelpers(page, component);

  const { pagination: paginationRef }: any = component.getModels();
  const pagination = page.getContent(paginationRef);

  const articleGroup = (pagination as any)?.model.items.map((item: any) => {
    return page.getContent(item);
  });

  const properties = component.getProperties();
  const seeAllLinkContent = page.getContent(properties.seeAll);
  const seeAllLink = isDocument(seeAllLinkContent) ? seeAllLinkContent.getUrl() : "";

  return {
    title: properties?.title,
    articles: articleGroup?.map(({ model: article }: any) => {
      return {
        ...article?.data,
        image: getSrcOf(article?.data.image),
        link: getArticleLink(article?.data),
        date: formatArticleDate(article?.data.date),
      };
    }),
    seeAll: {
      label: properties?.seeAllLabel,
      link: seeAllLink,
    },
    breakpoints: {
      320: {
        slidesPerView: 1.2,
      },
      520: {
        slidesPerView: 1.4,
      },
      768: {
        slidesPerView: 2.2,
      },
      960: {
        slidesPerView: 3.1,
      },
      1024: {
        slidesPerView: articleGroup?.length,
      },
    },
  };
}

export function formatArticleDate(date: string) {
  return new Date(date).toLocaleDateString("en-Us", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
}

export function getArticleLink(article: any) {
  const { category, name } = article || {};
  return `/blog/${category?.selectionValues[0]?.key}/${name}`;
}

<template>
  <Stack data-test-id="loginRationale" direction="col" gap="md" class="px-xs md:px-none w-full md:w-[516px]">
    <Text class="text-center md:text-start ui-text-md md:ui-text-2xl" weight="semibold">{{ header }}</Text>
    <Stack direction="col" gap="xs">
      <Stack v-for="(rationale, index) in rationaleItems" gap="xs" align="center" :key="index">
        <template v-if="rationale?.title?.length > 0">
          <SvgIcon :name="useBloomreachIconMapping(rationale.image.selectionValues[0].key)" :width="24" :height="24" />
          <Text size="md">{{ rationale.title }}</Text>
        </template>
      </Stack>
    </Stack>
  </Stack>
</template>

<script lang="ts" setup>
import { Component, Page } from "@bloomreach/spa-sdk";
import { useLogin } from "~/composables/useLogin";
import useBloomreachIconMapping from "~/composables/useIconMappings";

const { component, page } = defineProps<{ component: Component; page: Page }>();
const { rationaleItems, header } = useLogin(page, component);
</script>

import type { Component, Page } from "@bloomreach/spa-sdk";

export function useImageContainer(page: Page, component: Component) {
  const { getLinkOf, getSrcOf } = useContentHelpers(page, component);
  const { responsiveImageContainer } = component.getModels();
  const data = (page.getContent(responsiveImageContainer) as any)?.model.data.responsiveImageContainer;

  return {
    desktopImage: getSrcOf(data?.desktopImage),
    mobileImage: getSrcOf(data?.mobileImage),
    link: getLinkOf(data?.link),
    altTag: data?.altTag,
  };
}
